<template>
  <information-panel>
    <template v-slot:header>
      <v-icon>mdi-format-list-bulleted</v-icon>
      <p class="text-h6 font-weight-bold ml-3 mb-0">
        Details
      </p>
    </template>
    <template v-slot:content>
      <v-list
        class="transparent"
        dense
      >
        <v-list-item class="justify-space-between">
          <v-list-item-title>Contract Address</v-list-item-title>
          <div class="text-right overflow-x-auto subtitle-2 font-weight-light">
          {{nft.metadata.contractAddress}}
          </div>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>Token ID</v-list-item-title>
          <div class="text-right overflow-x-auto subtitle-2 font-weight-light">
          {{tokenID}}
          </div>
        </v-list-item>
        <v-list-item class="justify-space-between">
          <v-list-item-title>Token Standard</v-list-item-title>
          <v-list-item-subtitle class="text-right">ERC721</v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="justify-space-between">
          <v-list-item-title>Blockchain</v-list-item-title>
          <v-list-item-subtitle class="text-right">{{nft.blockchain}}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="justify-space-between">
          <v-list-item-title>Loyalty Fee</v-list-item-title>
          <v-list-item-subtitle class="text-right">{{`${nft.loyaltyFee}%`}}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item class="justify-space-between" v-show = "nft.status.minted">
          <v-list-item-title>IPFS</v-list-item-title>
          <div class="text-right overflow-x-auto subtitle-2 font-weight-light">
          {{ifpsLink}}
          </div>
        </v-list-item>
      </v-list>
    </template>
  </information-panel>
</template>

<script>
import BigNumber from 'bignumber.js';

export default {
  name: 'NFTListingDetails',
  props: {
    nft: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ifpsLink() {
      return `https://ipfs.io/ipfs/${this.nft.ipfsURL}`;
    },
    tokenID() {
      if (this.nft) {
        if (this.nft.metadata.tokenID === 'unminted') {
          return 'unminted';
        }
        const convertedID = BigNumber(this.nft.metadata.tokenID);
        console.log(convertedID);
        return convertedID.toString();
      }
      return '';
    },
  },
};
</script>
<style>

</style>
